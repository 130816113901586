import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Styles
import "../styles/navbar.css";

// Icons
import SearchIcon from "../assets/navbarIcons/SearchIcon.png";
import CartIcon from "../assets/navbarIcons/CartIcon.png";
import AccountIcon from "../assets/navbarIcons/AccountIcon.png";
import BurgerBtnIcon from "../assets/navbarIcons/BurgerBtnIcon.png";

// Components
import FullscreenView from "./FullscreenView";
import { InputDropdown } from "./Inputs";

// Apis
import { logoutUser } from "../api/authentications";
import { getAllCurrencies } from "../api/currencies";
import { searchAll } from "../api/products";

// Helpers
import { handleFetchUsersShoppingCarts } from "../helpers/handleFetchUsersShoppingCarts";
import { timer } from "../helpers/timer";
import { handleAttachQuery } from "../helpers/attach-queries";

// Cookies
import Cookies from "js-cookie";

let autocompleteTimer

const Navbar = (props) => {
  const { totalUsersCart, propCategories } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector((state) => state.userDetail);
  const searchState = useSelector((state) => state.searchState);
  const authenticationModal = useSelector((state) => state.authenticationModal);
  const storeDetails = useSelector((state) => state.storeDetails);
  const paramsTokenResetPassword = useSelector(
    (state) => state.paramsTokenResetPassword
  );
  const productListPageState = useSelector(
    (state) => state.productListPageState
  );
  const isMobileSize = useSelector((state) => state.isMobileSize);

  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [subMenuNavbar, setSubMenuNavbar] = useState({
    isOpen: false,
    subMenu: "",
    subCategoriesList: [],
  });
  const [savedSubMenuNavbar, setSavedSubMenuNavbar] = useState({
    isOpen: false,
    subMenu: "",
    subCategoriesList: [],
  });
  const [searchKey, setSearchKey] = useState("");
  const [isOpenFloatingAccountMenu, setIsOpenFloatingAccountMenu] =
    useState(false);
  const [displaySearchInput, setDisplaySearchInput] = useState(false);
  const [isOpenMobileNavbar, setIsOpenMobileNavbar] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [categories, setCategories] = useState([]);
  const [onMouseOverOnEachCategory, setOnMouseOverOnEachCategory] = useState({
    name: "",
    image_url: "",
  });

  useEffect(() => {
    if (paramsTokenResetPassword) {
      dispatch({
        type: "SET_AUTHENTICATION_MODAL",
        authenticationModal: {
          ...authenticationModal,
          resetPassword: true,
          tokenResetPassword: paramsTokenResetPassword,
        },
      });
    }
  }, [paramsTokenResetPassword]);

  const handleFetchCurrencyOptions = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await getAllCurrencies();
    if (result && result.data) {
      let finalCurrencyOptions = result.data;
      finalCurrencyOptions = finalCurrencyOptions.map((currOpt) => {
        currOpt.label = `${currOpt.code} (${currOpt.name})`;
        return currOpt;
      });
      setCurrencyOptions(finalCurrencyOptions);
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const handleFormatCategories = async () => {
    const categoryListResponse = propCategories;
    let finalCategoriesToFetch = [];
    if (
      categoryListResponse &&
      Array.isArray(categoryListResponse) &&
      categoryListResponse.length > 0
    ) {
      for (let c = 0; c < categoryListResponse.length; c++) {
        const category = categoryListResponse[c];
        if (c < 4) {
          finalCategoriesToFetch.push(category);
        } else {
          finalCategoriesToFetch.push({
            name: "View all products",
            categories: categoryListResponse,
          });
          break;
        }
      }
    }
    setCategories(finalCategoriesToFetch);
  };

  useEffect(() => {
    handleFetchCurrencyOptions();
  }, []);

  useEffect(() => {
    handleFormatCategories();
  }, [propCategories]);

  const handleOnClickNavbarCategory = (category, subCategory) => {
    let urlQueries;
    if (category && category.name && category.name !== "View all products") {
      if (!subCategory) {
        searchState.otherFilter = []
      }
      dispatch({
        type: "SET_SEARCH_STATE",
        searchState: {
          ...searchState,
          category,
          subCategory: subCategory ? subCategory : null,
          subCategories: category.sub_categories,
        },
      });
      urlQueries = handleAttachQuery(
        {
          ...searchState,
          category,
          subCategory: subCategory ? subCategory : null,
          subCategories: category.sub_categories,
        },
        {
          ...productListPageState,
          offset: 0,
        }
      );
      navigate(`/items?${urlQueries}`);
      setSubMenuNavbar({
        isOpen: false,
        subMenu: "",
        subCategoriesList: [],
      });
    } else {
      dispatch({
        type: "SET_SEARCH_STATE",
        searchState: {
          currencyVal: {
            label: "IDR (Indonesian Rupiah)",
            code: "IDR",
            name: "Indonesian Rupiah",
            id: 23,
          },
        },
      });
      urlQueries = handleAttachQuery(
        {
          currencyVal: {
            label: "IDR (Indonesian Rupiah)",
            code: "IDR",
            name: "Indonesian Rupiah",
            id: 23,
          },
        },
        {
          ...productListPageState,
          offset: 0,
        }
      );
      navigate(`/items?${urlQueries}`);
      setSubMenuNavbar({
        isOpen: false,
        subMenu: "",
        subCategoriesList: [],
      });
    }
    dispatch({
      type: "SET_PRODUCT_LIST_PAGE_STATE",
      productListPageState: {
        ...productListPageState,
        offset: 0,
        currentPage: 1,
      },
    });
    setIsOpenFloatingAccountMenu(false)
  };

  const handleOnClickSignOut = async () => {
    try {
      const result = await logoutUser();
      if (
        result &&
        result.data &&
        result.data.message &&
        result.data.message === "User logged out"
      ) {
        setIsOpenFloatingAccountMenu(false);
        navigate("/");
        Cookies.remove("ut");
        dispatch({ type: "SET_USER_DETAIL", userDetail: null });
        dispatch({ type: "SET_USERS_CART", shoppingCarts: [] });
        dispatch({ type: "SET_TOTAL_USERS_CART", totalUsersCart: null });
        const beforeFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: true,
              msg: "Successfully logged out",
              show: true,
            },
          });
        };
        const afterFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: false,
              msg: "",
              show: false,
            },
          });
        };
        timer(beforeFunction, afterFunction, 3000);
      }
    } catch (err) {
      console.log(err.response, "err <<<");
    }
  };

  const handleOnClickAccountMenu = (flagger) => {
    setIsOpenMobileNavbar(false);
    if (flagger !== "close-btn") {
      if (flagger === "account") {
        if (userDetail) {
          navigate("/my-profile");
        } else {
          dispatch({
            type: "SET_AUTHENTICATION_MODAL",
            authenticationModal: {
              ...authenticationModal,
              login: true,
            },
          });
        }
      } else {
        navigate("/items");
      }
    }
  };

  const handleOnChangeCurrency = (val) => {
    dispatch({
      type: "SET_SEARCH_STATE",
      searchState: {
        ...searchState,
        currencyVal: val,
      },
    });
    handleFetchUsersShoppingCarts(
      { ...searchState, currencyVal: val },
      dispatch
    );
  };

  useEffect(() => {
    if (searchKey) {
      if (autocompleteTimer) {
        clearTimeout(autocompleteTimer);
      }
      autocompleteTimer = setTimeout(() => handleOnSearch(searchKey), 1000)
    } else {
      setSearchValue(null);
    }
  }, [searchKey]);

  const handleOnSearch = async (searchKey) => {
    const body = {
      query: searchKey,
    };
    const result = await searchAll(body);
    if (result && result.data) {
      const { data } = result || {};
      setSearchValue(data);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScrollHandler);
  }, []);

  const onScrollHandler = () => {
    setSubMenuNavbar({
      isOpen: false,
      subMenu: "",
      subCategoriesList: [],
    });
  };

  const handleOnClickSearchItem = (selected, flagger) => {
    if (flagger === "category") {
      let category = selected;
      let subCategories = searchValue && searchValue.sub_categories;
      subCategories =
        subCategories &&
          Array.isArray(subCategories) &&
          subCategories.length > 0
          ? subCategories.filter((subCategory) => {
            if (subCategory.category_id === category.id) {
              subCategory.selected = false;
              return subCategory;
            }
          })
          : [];
      dispatch({
        type: "SET_SEARCH_STATE",
        searchState: { ...searchState, category, subCategories },
      });
      navigate(
        `/items?${handleAttachQuery(
          { ...searchState, category, subCategories },
          productListPageState
        )}`
      );
      setDisplaySearchInput(false);
    }
  };

  const renderSubMenu = () => {
    const { subMenu, subCategoriesList, categories } = subMenuNavbar;
    let randomCategoryObj = null;
    if (categories && Array.isArray(categories) && categories.length > 0) {
      randomCategoryObj =
        categories[Math.floor(Math.random() * categories.length)];
    }
    return (
      <div
        className="subMenuNavbarContainer"
        onMouseLeave={() => handleOnMouseLeaveSubMenu()}
      >
        <div className="subMenuContentContainer">
          <div className="eachSubMenuContainer">
            <div
              onClick={() =>
                subMenu !== "View all products"
                  ? handleOnClickNavbarCategory(subMenuNavbar.category)
                  : null
              }
            >
              <div>View All {subMenu === "View all products" ? "Category" : subMenu}</div>
            </div>
          </div>
          {
            subCategoriesList &&
            Array.isArray(subCategoriesList) &&
            subCategoriesList.length > 0 && (
              <>
                <div className="eachSubMenuContainer">
                  <div style={{ height: "45vh" }}>
                    {
                      subCategoriesList.slice(0, 5).length > 0 &&
                      subCategoriesList.slice(0, 5).map((sub_category, idx) => {
                        const { name } = sub_category;
                        return (
                          <div
                            key={idx}
                            onClick={() =>
                              handleOnClickNavbarCategory(
                                subMenuNavbar.category,
                                sub_category
                              )
                            }
                          >
                            <div>{name}</div>
                          </div>
                        );
                      })
                    }
                    {categories &&
                      Array.isArray(categories) &&
                      categories.length > 0 && (
                        <div style={{ overflow: "scroll", height: "45vh" }}>
                          {categories.map((category, idx) => {
                            const { name, image_url } = category;
                            return (
                              <div
                                key={idx}
                                onClick={() => handleOnClickNavbarCategory(category)}
                              >
                                <div
                                  onMouseEnter={() =>
                                    setOnMouseOverOnEachCategory({
                                      name,
                                      image_url,
                                    })
                                  }
                                >
                                  {name}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </div>
                </div>
                <div className="eachSubMenuContainer">
                  <div style={{ height: "45vh" }}>
                    {
                      subCategoriesList.slice(6, 10).length > 0 &&
                      subCategoriesList.slice(6, 10).map((sub_category, idx) => {
                        const { name } = sub_category;
                        return (
                          <div
                            key={idx}
                            onClick={() =>
                              handleOnClickNavbarCategory(
                                subMenuNavbar.category,
                                sub_category
                              )
                            }
                          >
                            <div>{name}</div>
                          </div>
                        );
                      })
                    }
                    {categories &&
                      Array.isArray(categories) &&
                      categories.length > 0 && (
                        <div style={{ overflow: "scroll", height: "45vh" }}>
                          {categories.map((category, idx) => {
                            const { name, image_url } = category;
                            return (
                              <div
                                key={idx}
                                onClick={() => handleOnClickNavbarCategory(category)}
                              >
                                <div
                                  onMouseEnter={() =>
                                    setOnMouseOverOnEachCategory({
                                      name,
                                      image_url,
                                    })
                                  }
                                >
                                  {name}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </div>
                </div>
              </>
            )
          }
          <div className="eachSubMenuContainer">
            {randomCategoryObj &&
              (randomCategoryObj.image_url ||
                onMouseOverOnEachCategory.image_url) ? (
              <div>
                <img
                  src={
                    onMouseOverOnEachCategory.image_url
                      ? onMouseOverOnEachCategory.image_url
                      : randomCategoryObj.image_url
                  }
                />
                <div>
                  {onMouseOverOnEachCategory.name
                    ? onMouseOverOnEachCategory.name
                    : randomCategoryObj.name}
                </div>
              </div>
            ) : null}
            {subMenuNavbar &&
              subMenuNavbar.category &&
              subMenuNavbar.category.image_url ? (
              <div className="eachSubMenuContainer">
                <div>
                  <img src={subMenuNavbar.category.image_url} />
                  <div>{subMenuNavbar.category.name}</div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const handleOnMouseEnterCategoryNavbar = (name, category, sub_categories) => {
    setIsOpenFloatingAccountMenu(false)
    if (name === "View all products") {
      setSubMenuNavbar({
        isOpen: false
      });
    } else {
      setSubMenuNavbar({
        isOpen: true,
        subMenu: name,
        subCategoriesList: sub_categories,
        category,
        categories: [],
      });
      setSavedSubMenuNavbar({
        isOpen: true,
        subMenu: name,
        subCategoriesList: sub_categories,
        category,
        categories: [],
      });
    }
  };

  const handleOnMouseLeaveSubMenu = () => {
    setSubMenuNavbar({
      isOpen: false,
      subMenu: "",
      subCategoriesList: [],
    });

    setSavedSubMenuNavbar({
      isOpen: false,
      subMenu: "",
      subCategoriesList: [],
      category: null,
      categories: [],
    });
    setIsOpenFloatingAccountMenu(false)
  }

  const handleNavigateAction = (direction) => {
    setIsOpenFloatingAccountMenu(false)
    switch (direction) {
      case 'home':
        navigate('/');
        break;
      case 'cart':
        navigate('/cart');
        break;
      case 'search':
        setDisplaySearchInput(true)
        break;
      default:
        break;
    }
  }

  return (
    <div className="navbarContainer">
      <div className="promoSection">
        <div className="promoValue">
          <div>20% OFF FOR YOUR FIRST ORDER. SHOP NOW</div>
          {!isMobileSize ? (
            <>
              <div>20% OFF FOR YOUR FIRST ORDER. SHOP NOW</div>
            </>
          ) : null}
        </div>
      </div>
      {displaySearchInput ? (
        <FullscreenView
          flagger={"navbar-search-input"}
          searchKey={searchKey}
          searchValue={searchValue}
          setSearchKey={setSearchKey}
          setterOpenFullscreen={setDisplaySearchInput}
          navigate={navigate}
          handleOnClickSearchItem={handleOnClickSearchItem}
        />
      ) : (
        <>
          {/* <div
            style={{ height: "2vh" }}
            onMouseEnter={() => {
              setSubMenuNavbar({
                isOpen: false,
                subMenu: "",
                subCategoriesList: [],
              });
              setSavedSubMenuNavbar({
                isOpen: false,
                subMenu: "",
                subCategoriesList: [],
              });
            }}
          ></div> */}
          <div className="navbarSection py-2">
            <div className="logoSection" onClick={() => handleNavigateAction('home')}>
              <img
                src={storeDetails?.logo_url}
                alt="swing-club-logo"
                className="w-32 sm:w-96 h-auto max-h-16 object-contain"
              />
            </div>
            {!isMobileSize ? (
              <div className="navbarItemsContainer">
                {categories &&
                  Array.isArray(categories) &&
                  categories.length > 0 &&
                  categories.map((category, index) => {
                    const { name, sub_categories, categories } = category || {};
                    return (
                      <div
                        key={index}
                        className="navbarItem"
                        onClick={() => handleOnClickNavbarCategory(category)}
                        style={{
                          fontWeight:
                            subMenuNavbar.subMenu === name ? "bold" : "400",
                        }}
                      >
                        <div
                          onMouseEnter={() =>
                            handleOnMouseEnterCategoryNavbar(
                              name,
                              category,
                              sub_categories
                            )
                          }
                        // onMouseLeave={() =>
                        //   name === "View all products"
                        //     ? setSavedSubMenuNavbar({
                        //         isOpen: true,
                        //         subMenu: name,
                        //         subCategoriesList: [],
                        //         category: null,
                        //         categories,
                        //       })
                        //     : setSavedSubMenuNavbar({
                        //         isOpen: true,
                        //         subMenu: name,
                        //         subCategoriesList: sub_categories,
                        //         category,
                        //         categories: [],
                        //       })
                        // }
                        >
                          {name}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : null}
            <div className="otherSections">
              {!isMobileSize ? (
                <div className="languageSection" onClick={() => handleNavigateAction('currency')}>
                  <InputDropdown
                    options={currencyOptions}
                    styles={{
                      border: "1px solid #000000",
                      borderRadius: "5px",
                      fontSize: "0.95vw",
                      cursor: "pointer",
                      width: "10vw",
                      textAlign: "left",
                    }}
                    optionStyles={{
                      textAlign: "left",
                      margin: "0.75vh 1vw 1vh 0.75vw",
                      fontSize: "0.95vw",
                      cursor: "pointer",
                    }}
                    value={searchState && searchState.currencyVal}
                    functionSetter={handleOnChangeCurrency}
                  />
                </div>
              ) : null}
              <div className="otherSectionsImgContainer">
                <img
                  src={SearchIcon}
                  alt="search-icon"
                  className="searchIcon"
                  onClick={() => handleNavigateAction('search')}
                ></img>
              </div>
              {!isMobileSize ? (
                <div className="otherSectionsImgContainer">
                  <img
                    src={CartIcon}
                    alt="cart-icon"
                    className="cartIcon"
                    onClick={() => handleNavigateAction('cart')}
                  ></img>
                  {totalUsersCart && totalUsersCart !== 0 ? (
                    <div 
                      className="totalUsersCartContainer" 
                      onClick={() => handleNavigateAction('cart')}
                    >
                      {totalUsersCart}
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div className="otherSectionsImgContainerAccount">
                <img
                  src={AccountIcon}
                  alt="account-icon"
                  className="accountIcon"
                  onClick={() => {
                    userDetail
                      ? setIsOpenFloatingAccountMenu(
                        !isOpenFloatingAccountMenu
                      )
                      : dispatch({
                        type: "SET_AUTHENTICATION_MODAL",
                        authenticationModal: {
                          ...authenticationModal,
                          login: true,
                        },
                      })
                  }}
                  onMouseEnter={(e) => {
                    userDetail
                      ? setIsOpenFloatingAccountMenu(
                        !isOpenFloatingAccountMenu
                      )
                      : e.preventDefault()
                  }}
                ></img>
              </div>
              {isMobileSize ? (
                <div className="otherSectionsImgContainer">
                  <img
                    src={CartIcon}
                    alt="cart-icon"
                    className="cartIcon"
                    onClick={() => handleNavigateAction('cart')}
                  ></img>
                  {totalUsersCart && totalUsersCart !== 0 ? (
                    <span className="totalUsersCartContainer">
                      {totalUsersCart}
                    </span>
                  ) : null}
                </div>
              ) : null}
              {isMobileSize ? (
                <img
                  src={BurgerBtnIcon}
                  alt="burger-btn-icon"
                  className="burgerBtnIcon"
                  onClick={() => setIsOpenMobileNavbar(true)}
                ></img>
              ) : null}
            </div>
          </div>
          {/* <div style={{ height: "2vh" }}></div> */}
        </>
      )}
      {subMenuNavbar && subMenuNavbar.isOpen ? (
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          {renderSubMenu()}
        </div>
      ) : null}
      {isOpenFloatingAccountMenu ? (
        <div className="accountMenuBoxContainer" onMouseLeave={() => setIsOpenFloatingAccountMenu(false)}>
          <div className="accountMenuBoxContentContainer" onMouseLeave={() => setIsOpenFloatingAccountMenu(false)}>
            <div
              className="eachAccountBoxMenuOptions"
              onClick={() => {
                setIsOpenFloatingAccountMenu(false);
                navigate("/my-profile");
              }}
            >
              <div className="flex flex-col">
                <span>My profile</span>
                <span className="eachAccountBoxMenuOptionsEmail">
                  {userDetail && userDetail.email}
                </span>
              </div>

            </div>
            <div
              className="eachAccountBoxMenuOptions"
              onClick={() => {
                setIsOpenFloatingAccountMenu(false);
                navigate("/wishlist");
              }}
            >
              <div>Wishlist</div>
            </div>
            <div
              className="eachAccountBoxMenuOptions"
              onClick={() => {
                setIsOpenFloatingAccountMenu(false);
                navigate("/my-orders");
              }}
            >
              <div>My orders</div>
            </div>
            <div
              className="eachAccountBoxMenuOptions"
              onClick={() => {
                setIsOpenFloatingAccountMenu(false);
                navigate("/my-address");
              }}
            >
              <div>My address</div>
            </div>
            <div
              className="eachAccountBoxMenuOptions"
              onClick={() => {
                setIsOpenFloatingAccountMenu(false);
                navigate("/my-promo-code");
              }}
            >
              <div>Promo code</div>
            </div>
            <div
              className="eachAccountBoxMenuOptions"
              style={{ marginBottom: "0em" }}
              onClick={() => handleOnClickSignOut()}
            >
              <div>Sign out</div>
            </div>
          </div>
        </div>
      ) : null}
      {authenticationModal &&
        (authenticationModal.login ||
          authenticationModal.register ||
          authenticationModal.forgotPassword ||
          authenticationModal.resetPassword) ? (
        <FullscreenView
          flagger={"authentication-form"}
          setterOpenFullscreen={(objValue) =>
            dispatch({
              type: "SET_AUTHENTICATION_MODAL",
              authenticationModal: objValue,
            })
          }
          authenticationState={authenticationModal}
          navigate={navigate}
        />
      ) : null}
      {isOpenMobileNavbar ? (
        <FullscreenView
          flagger={"navbar-mobile-menu"}
          setterOpenFullscreen={(flagger) => handleOnClickAccountMenu(flagger)}
          navigate={navigate}
          categories={categories}
          setterClickCategory={(flagger) => handleOnClickNavbarCategory(flagger)}
        />
      ) : null}
    </div>
  );
};

export default Navbar;
